import React, { useState } from "react";
import SearchResultPanel from "../components/SearchResultPanelWOB";
import AutoCompWrapper from "../components/AutoCompWrapperWOB";

const Home = () => {
  const [query, setQuery] = useState("");
  return (
    <>
      <AutoCompWrapper setQuery={setQuery} dev={"-wob"}/>
      <div className={`main-panel main-panel-wob`}>
       
        <SearchResultPanel query={query} setQuery={setQuery} dev={"-wob"}/>
      </div>
    </>
  );
};


export default Home;
