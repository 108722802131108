import React, { memo } from "react";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import Autocomplete from "./AutoComWOB";
import { ProductItem } from "./ProductItem";

const appId = "AR33G9NJGJ";
const apiKey = "75493ee01799de95f5dd2bfa938fb4be";
const searchClient = algoliasearch(appId, apiKey);
const AutoCompWrapper = memo(({ setQuery,dev }) => {
  return (
    <div className="app-container app-container-wob">
      <Autocomplete
        openOnFocus={false}
        setQuery={setQuery}
        placeholder="Search over 7 million books"
        
        dev={dev}
      />
    </div>
  );
});

export default AutoCompWrapper;
