import React, { useEffect, useState } from "react";
import PanelTopSection from "./PanelTopSectionArne";
import PanelBottomSection from "./PanelBottomSectionArne";
import axios from "axios";

const SearchResultPanel = ({ query, setQuery, dev}) => {
  const [data,setData]=useState({})
  const [loading, setLoading] = useState(false);

  const getQueryData = async () => {
    try {
      setLoading(true)
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://BANMESGS7W-dsn.algolia.net/1/indexes/shopify_arneukproducts/?query=${query}`,
        headers: {
          "X-Algolia-Application-Id": "BANMESGS7W",
          "X-Algolia-API-Key": "b2f5b298d5944c1a4a82a79eb52f5ae4",
        },
      };
      const response = await axios.request(config);
      if (response.status === 200) {
        console.log("SEARCH", response)
        setLoading(false)
        setData(response.data);
      }
    } catch (error) {
        setLoading(false)
        console.log(error);
    }
  };
  useEffect(() => {
    getQueryData();
  }, [query]);
  return (
    <div className="panel-layout">
      <PanelTopSection query={query} setQuery={setQuery} dev={dev}/>
      <PanelBottomSection query={query} setQuery={setQuery} loading={loading} data={data}/>
    </div>
  );
};

export default SearchResultPanel;
